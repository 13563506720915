<template>
  <material-card
    color="primary"
    :icon="icon || undefined"
    @click="$emit('click')"
    class="primary--text ma-0 pa-0"
    :class="color"
    width="100%"
    :fullHeader="!!notification || undefined"
    :notification="notification || undefined"
  >
    <!-- title -->
    <v-card-title class="text-h4" style="
        font-family:'Poppins'; font-weight:800;
    ">
        {{title}}
    </v-card-title>
    <v-card-subtitle style="color:#4A4646" v-html="text"></v-card-subtitle>

    <v-card-actions v-if="events.length">
      <v-btn
        text
        v-for="(event, index) in events"
        :loading="actions.loading"
        :disabled="actions.loading"
        :key="index"
        @click="$emit(event.event)"
        :class="event.class || ''"
      >{{event.title || "action"}}</v-btn>
    </v-card-actions>
  </material-card>
</template>

<script>
export default {
  props: {
    icon:'',
    notification:"",
    actions: {
      type: Object,
      default: () => ({ loading: false })
    },
    title: "",
    text: "",
    color: {
      type: String,
      default: "#ffffff"
    },
    events: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style>
</style>